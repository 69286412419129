/* stylelint-disable-next-line scss/dollar-variable-pattern */
/* stylelint-disable-next-line scss/dollar-variable-pattern */
@font-face {
  font-family: "Source Sans Pro";
  font-weight: 425;
  font-style: normal;
  src: url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Light.otf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Light.ttf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Light.otf.woff") format("woff"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Light.ttf.woff") format("woff");
}
@font-face {
  font-family: "Source Sans Pro";
  font-weight: 500;
  font-style: normal;
  src: url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Regular.otf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Regular.ttf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Regular.otf.woff") format("woff"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Regular.ttf.woff") format("woff");
}
@font-face {
  font-family: "Source Sans Pro";
  font-weight: 575;
  font-style: normal;
  src: url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Semibold.otf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Semibold.ttf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Semibold.otf.woff") format("woff"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-pro/SourceSansPro-Semibold.ttf.woff") format("woff");
}
@font-face {
  font-family: "Source Sans Variable";
  font-weight: 200 900;
  font-style: normal;
  src: url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-variable/SourceSansVariable-Roman.ttf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-variable/SourceSansVariable-Roman.ttf.woff") format("woff");
}
@font-face {
  font-family: "Source Sans Variable";
  font-weight: 200 900;
  font-style: italic;
  src: url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-variable/SourceSansVariable-Italic.ttf.woff2") format("woff2"), url("https://contenu-mpo.monportail.ulaval.ca/mpo/packages/@ul/mpo-assets/1.2.0/source-sans-variable/SourceSansVariable-Italic.ttf.woff") format("woff");
}
/* stylelint-disable-next-line scss/dollar-variable-pattern */
/* stylelint-disable-next-line scss/dollar-variable-pattern */
.mu-app-root {
  --m-color-ul-blue: #09f;
  --m-color-ul-yellow: #ffc103;
  --m-color-ul-red: #e30513;
  --m-color-grey-black: #1f1f1f;
  --m-color-grey-darkest: #2e2e2e;
  --m-color-grey-darker: #3d3d3d;
  --m-color-grey-dark: #696969;
  --m-color-grey: #929292;
  --m-color-grey-light: #d7d7d7;
  --m-color-grey-lighter: #f4f4f4;
  --m-color-grey-lightest: #f9f9f9;
  --m-color-black: #000;
  --m-color-white: #fff;
  --m-color-text: var(--m-color-black);
  --m-color-text-light: var(--m-color-grey-darker);
  --m-color-interactive-darker: #0b46ac;
  --m-color-interactive-dark: #1854be;
  --m-color-interactive: #096cdc;
  --m-color-interactive-light: var(--m-color-ul-blue);
  --m-color-interactive-lightest: #eaf5ff;
  --m-color-information: var(--m-color-interactive-lightest);
  --m-color-accent-dark: #ca7300;
  --m-color-accent: #db7e00;
  --m-color-accent-light: #f39612;
  --m-color-accent-lightest: #ffeecf;
  --m-color-success-dark: #086c30;
  --m-color-success: #0a873c;
  --m-color-success-light: #baebc7;
  --m-color-success-lightest: #e8feef;
  --m-color-warning: var(--m-color-accent);
  --m-color-warning-lightest: var(--m-color-accent-lightest);
  --m-color-error-dark: #b3040f;
  --m-color-error: var(--m-color-ul-red);
  --m-color-error-lightest: #fcf2f3;
  --m-color-active: var(--m-color-ul-yellow);
  --m-color-disabled-dark: var(--m-color-grey-dark);
  --m-color-disabled: var(--m-color-grey);
  --m-color-disabled-light: var(--m-color-grey-light);
  --m-color-border-dark: var(--m-color-grey-dark);
  --m-color-border: var(--m-color-grey-light);
  --m-color-border-light: var(--m-color-grey-lighter);
  --m-color-scrollbar: var(--m-color-grey);
  --m-color-scrollbar-light: var(--m-color-grey-light);
  --m-font-size-h1: 26;
  --m-font-size-h2: 24;
  --m-font-size-h3: 22;
  --m-font-size-h4: 20;
  --m-font-size-h5: 18;
  --m-font-size-h6: 16;
  --m-line-height: 1.3;
  --m-line-height-heading: 1.1;
}
@media (min-width: 768px) {
  .mu-app-root {
    --m-font-size-h1: 36;
    --m-font-size-h2: 32;
    --m-font-size-h3: 28;
    --m-font-size-h4: 24;
    --m-font-size-h5: 20;
    --m-font-size-h6: 16;
  }
}
.mu-app-root {
  margin: 0;
  box-sizing: border-box;
  font-style: normal;
  color: var(--m-color-text);
  font-weight: 350;
  font-size: inherit;
  line-height: var(--m-line-height);
  font-family: "Source Sans Pro", Arial, sans-serif;
}
body.mu-app-root {
  min-width: 320px;
}

@supports (font-variation-settings: normal) {
  .mu-app-root {
    font-family: "Source Sans Variable", Arial, sans-serif;
  }
}
.mu-app-root *,
.mu-app-root *::before,
.mu-app-root *::after {
  box-sizing: inherit;
}
.mu-app-root * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mu-app-root ::selection {
  background: var(--m-color-ul-yellow);
  color: var(--m-color-text);
}
.mu-app-root ul,
.mu-app-root li {
  margin: inherit 0 0;
  padding: 0;
}
.mu-app-root abbr {
  text-decoration: none;
  box-shadow: 0 1px var(--m-color-grey-dark);
}
.mu-app-root a {
  color: var(--m-color-interactive);
}
.mu-app-root h1,
.mu-app-root h2,
.mu-app-root h3,
.mu-app-root h4,
.mu-app-root h5,
.mu-app-root h6 {
  font-weight: 500;
  line-height: var(--m-line-height-heading);
  font-family: "Source Sans Pro", Arial, sans-serif;
}
@supports (font-variation-settings: normal) {
  .mu-app-root h1,
  .mu-app-root h2,
  .mu-app-root h3,
  .mu-app-root h4,
  .mu-app-root h5,
  .mu-app-root h6 {
    font-family: "Source Sans Variable", Arial, sans-serif;
  }
}
.mu-app-root h1 {
  margin: 0;
}
.mu-app-root h1 {
  font-size: calc(var(--m-font-size-h1) / 16 * 1rem);
}
.mu-app-root h2 {
  margin: 32px 0 0;
}
.mu-app-root h2 {
  font-size: calc(var(--m-font-size-h2) / 16 * 1rem);
}
.mu-app-root h3 {
  margin: 32px 0 0;
}
.mu-app-root h3 {
  font-size: calc(var(--m-font-size-h3) / 16 * 1rem);
}
.mu-app-root h4 {
  margin: 24px 0 0;
}
.mu-app-root h4 {
  font-size: calc(var(--m-font-size-h4) / 16 * 1rem);
}
.mu-app-root h5 {
  margin: 24px 0 0;
}
.mu-app-root h5 {
  font-size: calc(var(--m-font-size-h5) / 16 * 1rem);
}
.mu-app-root h6 {
  margin: 24px 0 0;
}
.mu-app-root h6 {
  font-size: calc(var(--m-font-size-h6) / 16 * 1rem);
}
.mu-app-root p {
  margin: 12px 0 0;
}
.mu-app-root b,
.mu-app-root strong {
  font-weight: 500;
}
.mu-app-root i,
.mu-app-root em {
  font-style: italic;
}
.mu-app-root small {
  font-size: calc(14 / 16 * 1rem);
}
.mu-app-root pre,
.mu-app-root code {
  word-break: break-all;
  word-wrap: break-word;
  font-weight: 350;
  color: var(--m-color-text);
}
.mu-app-root pre,
.mu-app-root code {
  font-size: calc(14 / 16 * 1rem);
}
.mu-app-root .mu-require {
  margin-top: -16px !important;
  float: right;
}
.mu-app-root .mu-asterisk {
  color: var(--m-color-accent);
  font-weight: 575;
}
.mu-app-root .mu-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.mu-app-root .mu-table th {
  padding: 0 8px 6px;
  font-weight: 500;
  color: var(--m-color-black);
  border-bottom: 2px solid var(--m-color-border);
  text-align: left;
}
.mu-app-root .mu-table th:first-child {
  padding-left: 16px;
}
.mu-app-root .mu-table th:last-child {
  padding-right: 16px;
}
.mu-app-root .mu-table tbody tr {
  border-bottom: 1px solid var(--m-color-border);
}
.mu-app-root .mu-table td {
  padding: 16px 8px;
  margin: 1px 0;
  vertical-align: top;
  text-align: left;
}
.mu-app-root .mu-table td:first-child {
  padding-left: 16px;
}
.mu-app-root .mu-table td:last-child {
  padding-right: 16px;
}
.mu-app-root .mu-table.m-u--has-icon th:last-child:not(:only-child),
.mu-app-root .mu-table.m-u--has-icon td:last-child:not(:only-child) {
  padding: 0;
  width: 44px;
  text-align: center;
  vertical-align: middle;
}
.m-panel .mu-app-root .mu-table tbody tr:last-child {
  border-bottom: 0;
}
.mu-app-root .mu-bullet-list {
  margin-top: 16px;
  margin-bottom: 0;
}
.mu-app-root .mu-bullet-list,
.mu-app-root .mu-bullet-list ul,
.mu-app-root .mu-bullet-list ol {
  padding: 0 0 0 24px;
}
.mu-app-root .mu-bullet-list ul,
.mu-app-root .mu-bullet-list ol {
  margin: 0;
}
.mu-app-root .mu-bullet-list li {
  position: relative;
  list-style: none;
  margin: 8px 0 0;
}
.mu-app-root .mu-bullet-list li::before {
  position: absolute;
  top: 10px;
  left: -12px;
  content: "";
  transform: translate3d(-50%, 0, 0);
}
.mu-app-root .mu-bullet-list li:first-child {
  margin: 0;
}
.mu-app-root .mu-bullet-list li > ul {
  margin: 12px 0 0;
}
.mu-app-root .mu-bullet-list > li > ul > li::before, .mu-app-root .mu-bullet-list > li::before,
.mu-app-root .mu-bullet-list > li > ul > li > ul > li::before,
.mu-app-root .mu-bullet-list > li > ul > li > ul > li > ul > li::before {
  width: 4px;
  height: 4px;
}
.mu-app-root .mu-bullet-list > li > ul > li::before, .mu-app-root .mu-bullet-list > li::before {
  border-radius: 50%;
  border: 1px solid var(--m-color-grey-dark);
}
.mu-app-root .mu-bullet-list > li::before,
.mu-app-root .mu-bullet-list > li > ul > li > ul > li::before {
  background: var(--m-color-grey-dark);
}
.mu-app-root .mu-bullet-list > li > ul > li > ul > li::before,
.mu-app-root .mu-bullet-list > li > ul > li > ul > li > ul > li::before {
  border: 1px solid var(--m-color-grey-dark);
}
.mu-app-root .mu-bullet-list > li > ul > li > ul > li > ul > li > ul > li::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 4px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--m-color-grey-dark);
}
.mu-app-root .mu-button-primary {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-primary {
  color: var(--m-color-white);
  background: var(--m-color-interactive);
}
.mu-app-root .mu-button-primary {
  cursor: pointer;
}
.mu-app-root .mu-button-primary:hover {
  color: var(--m-color-white);
  background: var(--m-color-interactive-dark);
  border-color: var(--m-color-interactive-dark);
}
.mu-app-root .mu-button-primary:active, .mu-app-root .mu-button-primary:focus:not(:hover) {
  color: var(--m-color-white);
  background: var(--m-color-interactive-darker);
  border-color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-button-secondary {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-secondary {
  color: var(--m-color-interactive);
  background: var(--m-color-white);
}
.mu-app-root .mu-button-secondary {
  cursor: pointer;
}
.mu-app-root .mu-button-secondary:hover {
  color: var(--m-color-white);
  background: var(--m-color-interactive-dark);
  border-color: var(--m-color-interactive-dark);
}
.mu-app-root .mu-button-secondary:active, .mu-app-root .mu-button-secondary:focus:not(:hover) {
  color: var(--m-color-white);
  background: var(--m-color-interactive-darker);
  border-color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-button-disabled {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-disabled {
  cursor: default;
  color: var(--m-color-disabled);
  background: var(--m-color-white);
  border-color: var(--m-color-disabled);
}
.mu-app-root .mu-button-disabled {
  cursor: pointer;
}
.mu-app-root .mu-button-disabled:hover {
  color: var(--m-color-white);
  background: var(--m-color-interactive-dark);
  border-color: var(--m-color-interactive-dark);
}
.mu-app-root .mu-button-disabled:active, .mu-app-root .mu-button-disabled:focus:not(:hover) {
  color: var(--m-color-white);
  background: var(--m-color-interactive-darker);
  border-color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-button-waiting {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-waiting {
  cursor: progress;
  color: var(--m-color-white);
  background: var(--m-color-interactive-light);
  border-color: var(--m-color-interactive-light);
}
.mu-app-root .mu-link,
.mu-app-root .mu-link-icon {
  transition: color 0.3s ease;
  position: relative;
  font-weight: 500;
  color: var(--m-color-interactive);
  outline-color: var(--m-color-interactive-darker);
  text-decoration: none;
}
.mu-app-root .mu-link:active, .mu-app-root .mu-link:focus:not(:hover),
.mu-app-root .mu-link-icon:active,
.mu-app-root .mu-link-icon:focus:not(:hover) {
  color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-link:not(:hover, :focus):visited,
.mu-app-root .mu-link-icon:not(:hover, :focus):visited {
  color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-link:hover, .mu-app-root .mu-link:focus {
  color: var(--m-color-interactive-dark);
  text-decoration: underline;
}
.mu-app-root .mu-link__text {
  position: relative;
  vertical-align: baseline;
}
.mu-app-root .mu-link-icon {
  display: inline-flex;
  align-items: baseline;
}
.mu-app-root .mu-link-icon:hover .mu-link__text, .mu-app-root .mu-link-icon:focus .mu-link__text {
  text-decoration: underline;
}
.mu-app-root .mu-link-icon > :not(:first-child) {
  margin-left: 8px;
}
.mu-app-root .mu-h1,
.mu-app-root .mu-h2,
.mu-app-root .mu-h3,
.mu-app-root .mu-h4,
.mu-app-root .mu-h5,
.mu-app-root .mu-h6 {
  font-weight: 500;
  line-height: var(--m-line-height-heading);
  font-family: "Source Sans Pro", Arial, sans-serif;
}
@supports (font-variation-settings: normal) {
  .mu-app-root .mu-h1,
  .mu-app-root .mu-h2,
  .mu-app-root .mu-h3,
  .mu-app-root .mu-h4,
  .mu-app-root .mu-h5,
  .mu-app-root .mu-h6 {
    font-family: "Source Sans Variable", Arial, sans-serif;
  }
}
.mu-app-root .mu-h1 {
  margin: 0;
}
.mu-app-root .mu-h1 {
  font-size: calc(var(--m-font-size-h1) / 16 * 1rem);
}
.mu-app-root .mu-h2 {
  margin: 32px 0 0;
}
.mu-app-root .mu-h2 {
  font-size: calc(var(--m-font-size-h2) / 16 * 1rem);
}
.mu-app-root .mu-h3 {
  margin: 32px 0 0;
}
.mu-app-root .mu-h3 {
  font-size: calc(var(--m-font-size-h3) / 16 * 1rem);
}
.mu-app-root .mu-h4 {
  margin: 24px 0 0;
}
.mu-app-root .mu-h4 {
  font-size: calc(var(--m-font-size-h4) / 16 * 1rem);
}
.mu-app-root .mu-h5 {
  margin: 24px 0 0;
}
.mu-app-root .mu-h5 {
  font-size: calc(var(--m-font-size-h5) / 16 * 1rem);
}
.mu-app-root .mu-h6 {
  margin: 24px 0 0;
}
.mu-app-root .mu-h6 {
  font-size: calc(var(--m-font-size-h6) / 16 * 1rem);
}
.mu-app-root .mu-p {
  margin: 12px 0 0;
}
.mu-app-root .mu-font-size {
  font-size: 1rem;
}
.mu-app-root .mu-font-size-lg {
  font-size: calc(20 / 16 * 1rem);
}
.mu-app-root .mu-font-size-md {
  font-size: calc(18 / 16 * 1rem);
}
.mu-app-root .mu-font-size-sm {
  font-size: calc(14 / 16 * 1rem);
}
.mu-app-root .mu-font-size-xs {
  font-size: calc(12 / 16 * 1rem);
}
.mu-app-root .mu-font-style-normal {
  font-style: normal;
}
.mu-app-root .mu-font-style-italic {
  font-style: italic;
}
.mu-app-root .mu-font-weight-black {
  font-weight: 650;
}
.mu-app-root .mu-font-weight-bold {
  font-weight: 575;
}
.mu-app-root .mu-font-weight-semi-bold {
  font-weight: 500;
}
.mu-app-root .mu-font-weight-regular {
  font-weight: 425;
}
.mu-app-root .mu-font-weight-light {
  font-weight: 350;
}
.mu-app-root .mu-label {
  font-weight: 500;
}
.mu-app-root .mu-label {
  font-size: 1rem;
}
.mu-app-root .mu-typo-precision, .mu-app-root .mu-typo-precision-italic {
  color: var(--m-color-text-light);
}
.mu-app-root .mu-typo-precision, .mu-app-root .mu-typo-precision-italic {
  font-size: calc(14 / 16 * 1rem);
}
.mu-app-root .mu-typo-precision-italic {
  font-style: italic;
}
.mu-app-root .mu-typo-highlighting {
  display: inline;
  font-weight: 500;
  background: var(--m-color-warning-lightest);
  box-shadow: 0 0 0 2px var(--m-color-warning-lightest);
}
.mu-app-root .mu-typo-highlighting {
  font-size: calc(14 / 16 * 1rem);
}
.mu-app-root .mu-d-block {
  display: block;
}
.mu-app-root .mu-d-flex {
  display: flex;
}
.mu-app-root .mu-d-inline {
  display: inline;
}
.mu-app-root .mu-d-inline-block {
  display: inline-block;
}
.mu-app-root .mu-d-inline-flex {
  display: inline-flex;
}
.mu-app-root .mu-max-w {
  max-width: 1000px;
}
.mu-app-root .mu-max-w-xs {
  max-width: 400px;
}
.mu-app-root .mu-max-w-sm {
  max-width: 800px;
}
.mu-app-root .mu-max-w-lg {
  max-width: 1200px;
}
.mu-app-root .mu-max-w-text {
  max-width: 720px;
}
.mu-app-root .mu-min-w {
  min-width: 320px;
}
.mu-app-root .mu-m-4xl {
  margin: 64px;
}
.mu-app-root .mu-m-3xl {
  margin: 56px;
}
.mu-app-root .mu-m-2xl {
  margin: 48px;
}
.mu-app-root .mu-m-xl {
  margin: 40px;
}
.mu-app-root .mu-m-lg {
  margin: 32px;
}
.mu-app-root .mu-m-md {
  margin: 24px;
}
.mu-app-root .mu-m {
  margin: 16px;
}
.mu-app-root .mu-m-sm {
  margin: 12px;
}
.mu-app-root .mu-m-xs {
  margin: 8px;
}
.mu-app-root .mu-m-2xs {
  margin: 4px;
}
.mu-app-root .mu-mt-4xl {
  margin-top: 64px;
}
.mu-app-root .mu-mt-3xl {
  margin-top: 56px;
}
.mu-app-root .mu-mt-2xl {
  margin-top: 48px;
}
.mu-app-root .mu-mt-xl {
  margin-top: 40px;
}
.mu-app-root .mu-mt-lg {
  margin-top: 32px;
}
.mu-app-root .mu-mt-md {
  margin-top: 24px;
}
.mu-app-root .mu-mt {
  margin-top: 16px;
}
.mu-app-root .mu-mt-sm {
  margin-top: 12px;
}
.mu-app-root .mu-mt-xs {
  margin-top: 8px;
}
.mu-app-root .mu-mt-2xs {
  margin-top: 4px;
}
.mu-app-root .mu-mr-4xl {
  margin-right: 64px;
}
.mu-app-root .mu-mr-3xl {
  margin-right: 56px;
}
.mu-app-root .mu-mr-2xl {
  margin-right: 48px;
}
.mu-app-root .mu-mr-xl {
  margin-right: 40px;
}
.mu-app-root .mu-mr-lg {
  margin-right: 32px;
}
.mu-app-root .mu-mr-md {
  margin-right: 24px;
}
.mu-app-root .mu-mr {
  margin-right: 16px;
}
.mu-app-root .mu-mr-sm {
  margin-right: 12px;
}
.mu-app-root .mu-mr-xs {
  margin-right: 8px;
}
.mu-app-root .mu-mr-2xs {
  margin-right: 4px;
}
.mu-app-root .mu-mb-4xl {
  margin-bottom: 64px;
}
.mu-app-root .mu-mb-3xl {
  margin-bottom: 56px;
}
.mu-app-root .mu-mb-2xl {
  margin-bottom: 48px;
}
.mu-app-root .mu-mb-xl {
  margin-bottom: 40px;
}
.mu-app-root .mu-mb-lg {
  margin-bottom: 32px;
}
.mu-app-root .mu-mb-md {
  margin-bottom: 24px;
}
.mu-app-root .mu-mb {
  margin-bottom: 16px;
}
.mu-app-root .mu-mb-sm {
  margin-bottom: 12px;
}
.mu-app-root .mu-mb-xs {
  margin-bottom: 8px;
}
.mu-app-root .mu-mb-2xs {
  margin-bottom: 4px;
}
.mu-app-root .mu-ml-4xl {
  margin-left: 64px;
}
.mu-app-root .mu-ml-3xl {
  margin-left: 56px;
}
.mu-app-root .mu-ml-2xl {
  margin-left: 48px;
}
.mu-app-root .mu-ml-xl {
  margin-left: 40px;
}
.mu-app-root .mu-ml-lg {
  margin-left: 32px;
}
.mu-app-root .mu-ml-md {
  margin-left: 24px;
}
.mu-app-root .mu-ml {
  margin-left: 16px;
}
.mu-app-root .mu-ml-sm {
  margin-left: 12px;
}
.mu-app-root .mu-ml-xs {
  margin-left: 8px;
}
.mu-app-root .mu-ml-2xs {
  margin-left: 4px;
}
.mu-app-root .mu-no-m {
  margin: 0;
}
.mu-app-root .mu-no-mt {
  margin-top: 0;
}
.mu-app-root .mu-no-mr {
  margin-right: 0;
}
.mu-app-root .mu-no-mb {
  margin-bottom: 0;
}
.mu-app-root .mu-no-ml {
  margin-left: 0;
}
.mu-app-root .mu-p-4xl {
  padding: 64px;
}
.mu-app-root .mu-p-3xl {
  padding: 56px;
}
.mu-app-root .mu-p-2xl {
  padding: 48px;
}
.mu-app-root .mu-p-xl {
  padding: 40px;
}
.mu-app-root .mu-p-lg {
  padding: 32px;
}
.mu-app-root .mu-p-md {
  padding: 24px;
}
.mu-app-root .mu-p {
  padding: 16px;
}
.mu-app-root .mu-p-sm {
  padding: 12px;
}
.mu-app-root .mu-p-xs {
  padding: 8px;
}
.mu-app-root .mu-p-2xs {
  padding: 4px;
}
.mu-app-root .mu-pt-4xl {
  padding-top: 64px;
}
.mu-app-root .mu-pt-3xl {
  padding-top: 56px;
}
.mu-app-root .mu-pt-2xl {
  padding-top: 48px;
}
.mu-app-root .mu-pt-xl {
  padding-top: 40px;
}
.mu-app-root .mu-pt-lg {
  padding-top: 32px;
}
.mu-app-root .mu-pt-md {
  padding-top: 24px;
}
.mu-app-root .mu-pt {
  padding-top: 16px;
}
.mu-app-root .mu-pt-sm {
  padding-top: 12px;
}
.mu-app-root .mu-pt-xs {
  padding-top: 8px;
}
.mu-app-root .mu-pt-2xs {
  padding-top: 4px;
}
.mu-app-root .mu-pr-4xl {
  padding-right: 64px;
}
.mu-app-root .mu-pr-3xl {
  padding-right: 56px;
}
.mu-app-root .mu-pr-2xl {
  padding-right: 48px;
}
.mu-app-root .mu-pr-xl {
  padding-right: 40px;
}
.mu-app-root .mu-pr-lg {
  padding-right: 32px;
}
.mu-app-root .mu-pr-md {
  padding-right: 24px;
}
.mu-app-root .mu-pr {
  padding-right: 16px;
}
.mu-app-root .mu-pr-sm {
  padding-right: 12px;
}
.mu-app-root .mu-pr-xs {
  padding-right: 8px;
}
.mu-app-root .mu-pr-2xs {
  padding-right: 4px;
}
.mu-app-root .mu-pb-4xl {
  padding-bottom: 64px;
}
.mu-app-root .mu-pb-3xl {
  padding-bottom: 56px;
}
.mu-app-root .mu-pb-2xl {
  padding-bottom: 48px;
}
.mu-app-root .mu-pb-xl {
  padding-bottom: 40px;
}
.mu-app-root .mu-pb-lg {
  padding-bottom: 32px;
}
.mu-app-root .mu-pb-md {
  padding-bottom: 24px;
}
.mu-app-root .mu-pb {
  padding-bottom: 16px;
}
.mu-app-root .mu-pb-sm {
  padding-bottom: 12px;
}
.mu-app-root .mu-pb-xs {
  padding-bottom: 8px;
}
.mu-app-root .mu-pb-2xs {
  padding-bottom: 4px;
}
.mu-app-root .mu-pl-4xl {
  padding-left: 64px;
}
.mu-app-root .mu-pl-3xl {
  padding-left: 56px;
}
.mu-app-root .mu-pl-2xl {
  padding-left: 48px;
}
.mu-app-root .mu-pl-xl {
  padding-left: 40px;
}
.mu-app-root .mu-pl-lg {
  padding-left: 32px;
}
.mu-app-root .mu-pl-md {
  padding-left: 24px;
}
.mu-app-root .mu-pl {
  padding-left: 16px;
}
.mu-app-root .mu-pl-sm {
  padding-left: 12px;
}
.mu-app-root .mu-pl-xs {
  padding-left: 8px;
}
.mu-app-root .mu-pl-2xs {
  padding-left: 4px;
}
.mu-app-root .mu-no-p {
  padding: 0;
}
.mu-app-root .mu-no-pt {
  padding-top: 0;
}
.mu-app-root .mu-no-pr {
  padding-right: 0;
}
.mu-app-root .mu-no-pb {
  padding-bottom: 0;
}
.mu-app-root .mu-no-pl {
  padding-left: 0;
}
.mu-app-root .mu-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
@media not print {
  .mu-app-root .mu-print-only {
    display: none !important;
  }
}
@media print {
  .mu-app-root .mu-no-print {
    display: none !important;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.mu-app-root .mu-d-block {
  display: block;
}
.mu-app-root .mu-d-flex {
  display: flex;
}
.mu-app-root .mu-d-inline {
  display: inline;
}
.mu-app-root .mu-d-inline-block {
  display: inline-block;
}
.mu-app-root .mu-d-inline-flex {
  display: inline-flex;
}
.mu-app-root .mu-max-w {
  max-width: 1000px;
}
.mu-app-root .mu-max-w-xs {
  max-width: 400px;
}
.mu-app-root .mu-max-w-sm {
  max-width: 800px;
}
.mu-app-root .mu-max-w-lg {
  max-width: 1200px;
}
.mu-app-root .mu-max-w-text {
  max-width: 720px;
}
.mu-app-root .mu-min-w {
  min-width: 320px;
}
.mu-app-root .mu-m-4xl {
  margin: 64px;
}
.mu-app-root .mu-m-3xl {
  margin: 56px;
}
.mu-app-root .mu-m-2xl {
  margin: 48px;
}
.mu-app-root .mu-m-xl {
  margin: 40px;
}
.mu-app-root .mu-m-lg {
  margin: 32px;
}
.mu-app-root .mu-m-md {
  margin: 24px;
}
.mu-app-root .mu-m {
  margin: 16px;
}
.mu-app-root .mu-m-sm {
  margin: 12px;
}
.mu-app-root .mu-m-xs {
  margin: 8px;
}
.mu-app-root .mu-m-2xs {
  margin: 4px;
}
.mu-app-root .mu-mt-4xl {
  margin-top: 64px;
}
.mu-app-root .mu-mt-3xl {
  margin-top: 56px;
}
.mu-app-root .mu-mt-2xl {
  margin-top: 48px;
}
.mu-app-root .mu-mt-xl {
  margin-top: 40px;
}
.mu-app-root .mu-mt-lg {
  margin-top: 32px;
}
.mu-app-root .mu-mt-md {
  margin-top: 24px;
}
.mu-app-root .mu-mt {
  margin-top: 16px;
}
.mu-app-root .mu-mt-sm {
  margin-top: 12px;
}
.mu-app-root .mu-mt-xs {
  margin-top: 8px;
}
.mu-app-root .mu-mt-2xs {
  margin-top: 4px;
}
.mu-app-root .mu-mr-4xl {
  margin-right: 64px;
}
.mu-app-root .mu-mr-3xl {
  margin-right: 56px;
}
.mu-app-root .mu-mr-2xl {
  margin-right: 48px;
}
.mu-app-root .mu-mr-xl {
  margin-right: 40px;
}
.mu-app-root .mu-mr-lg {
  margin-right: 32px;
}
.mu-app-root .mu-mr-md {
  margin-right: 24px;
}
.mu-app-root .mu-mr {
  margin-right: 16px;
}
.mu-app-root .mu-mr-sm {
  margin-right: 12px;
}
.mu-app-root .mu-mr-xs {
  margin-right: 8px;
}
.mu-app-root .mu-mr-2xs {
  margin-right: 4px;
}
.mu-app-root .mu-mb-4xl {
  margin-bottom: 64px;
}
.mu-app-root .mu-mb-3xl {
  margin-bottom: 56px;
}
.mu-app-root .mu-mb-2xl {
  margin-bottom: 48px;
}
.mu-app-root .mu-mb-xl {
  margin-bottom: 40px;
}
.mu-app-root .mu-mb-lg {
  margin-bottom: 32px;
}
.mu-app-root .mu-mb-md {
  margin-bottom: 24px;
}
.mu-app-root .mu-mb {
  margin-bottom: 16px;
}
.mu-app-root .mu-mb-sm {
  margin-bottom: 12px;
}
.mu-app-root .mu-mb-xs {
  margin-bottom: 8px;
}
.mu-app-root .mu-mb-2xs {
  margin-bottom: 4px;
}
.mu-app-root .mu-ml-4xl {
  margin-left: 64px;
}
.mu-app-root .mu-ml-3xl {
  margin-left: 56px;
}
.mu-app-root .mu-ml-2xl {
  margin-left: 48px;
}
.mu-app-root .mu-ml-xl {
  margin-left: 40px;
}
.mu-app-root .mu-ml-lg {
  margin-left: 32px;
}
.mu-app-root .mu-ml-md {
  margin-left: 24px;
}
.mu-app-root .mu-ml {
  margin-left: 16px;
}
.mu-app-root .mu-ml-sm {
  margin-left: 12px;
}
.mu-app-root .mu-ml-xs {
  margin-left: 8px;
}
.mu-app-root .mu-ml-2xs {
  margin-left: 4px;
}
.mu-app-root .mu-no-m {
  margin: 0;
}
.mu-app-root .mu-no-mt {
  margin-top: 0;
}
.mu-app-root .mu-no-mr {
  margin-right: 0;
}
.mu-app-root .mu-no-mb {
  margin-bottom: 0;
}
.mu-app-root .mu-no-ml {
  margin-left: 0;
}
.mu-app-root .mu-p-4xl {
  padding: 64px;
}
.mu-app-root .mu-p-3xl {
  padding: 56px;
}
.mu-app-root .mu-p-2xl {
  padding: 48px;
}
.mu-app-root .mu-p-xl {
  padding: 40px;
}
.mu-app-root .mu-p-lg {
  padding: 32px;
}
.mu-app-root .mu-p-md {
  padding: 24px;
}
.mu-app-root .mu-p {
  padding: 16px;
}
.mu-app-root .mu-p-sm {
  padding: 12px;
}
.mu-app-root .mu-p-xs {
  padding: 8px;
}
.mu-app-root .mu-p-2xs {
  padding: 4px;
}
.mu-app-root .mu-pt-4xl {
  padding-top: 64px;
}
.mu-app-root .mu-pt-3xl {
  padding-top: 56px;
}
.mu-app-root .mu-pt-2xl {
  padding-top: 48px;
}
.mu-app-root .mu-pt-xl {
  padding-top: 40px;
}
.mu-app-root .mu-pt-lg {
  padding-top: 32px;
}
.mu-app-root .mu-pt-md {
  padding-top: 24px;
}
.mu-app-root .mu-pt {
  padding-top: 16px;
}
.mu-app-root .mu-pt-sm {
  padding-top: 12px;
}
.mu-app-root .mu-pt-xs {
  padding-top: 8px;
}
.mu-app-root .mu-pt-2xs {
  padding-top: 4px;
}
.mu-app-root .mu-pr-4xl {
  padding-right: 64px;
}
.mu-app-root .mu-pr-3xl {
  padding-right: 56px;
}
.mu-app-root .mu-pr-2xl {
  padding-right: 48px;
}
.mu-app-root .mu-pr-xl {
  padding-right: 40px;
}
.mu-app-root .mu-pr-lg {
  padding-right: 32px;
}
.mu-app-root .mu-pr-md {
  padding-right: 24px;
}
.mu-app-root .mu-pr {
  padding-right: 16px;
}
.mu-app-root .mu-pr-sm {
  padding-right: 12px;
}
.mu-app-root .mu-pr-xs {
  padding-right: 8px;
}
.mu-app-root .mu-pr-2xs {
  padding-right: 4px;
}
.mu-app-root .mu-pb-4xl {
  padding-bottom: 64px;
}
.mu-app-root .mu-pb-3xl {
  padding-bottom: 56px;
}
.mu-app-root .mu-pb-2xl {
  padding-bottom: 48px;
}
.mu-app-root .mu-pb-xl {
  padding-bottom: 40px;
}
.mu-app-root .mu-pb-lg {
  padding-bottom: 32px;
}
.mu-app-root .mu-pb-md {
  padding-bottom: 24px;
}
.mu-app-root .mu-pb {
  padding-bottom: 16px;
}
.mu-app-root .mu-pb-sm {
  padding-bottom: 12px;
}
.mu-app-root .mu-pb-xs {
  padding-bottom: 8px;
}
.mu-app-root .mu-pb-2xs {
  padding-bottom: 4px;
}
.mu-app-root .mu-pl-4xl {
  padding-left: 64px;
}
.mu-app-root .mu-pl-3xl {
  padding-left: 56px;
}
.mu-app-root .mu-pl-2xl {
  padding-left: 48px;
}
.mu-app-root .mu-pl-xl {
  padding-left: 40px;
}
.mu-app-root .mu-pl-lg {
  padding-left: 32px;
}
.mu-app-root .mu-pl-md {
  padding-left: 24px;
}
.mu-app-root .mu-pl {
  padding-left: 16px;
}
.mu-app-root .mu-pl-sm {
  padding-left: 12px;
}
.mu-app-root .mu-pl-xs {
  padding-left: 8px;
}
.mu-app-root .mu-pl-2xs {
  padding-left: 4px;
}
.mu-app-root .mu-no-p {
  padding: 0;
}
.mu-app-root .mu-no-pt {
  padding-top: 0;
}
.mu-app-root .mu-no-pr {
  padding-right: 0;
}
.mu-app-root .mu-no-pb {
  padding-bottom: 0;
}
.mu-app-root .mu-no-pl {
  padding-left: 0;
}
.mu-app-root .mu-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
@media not print {
  .mu-app-root .mu-print-only {
    display: none !important;
  }
}
@media print {
  .mu-app-root .mu-no-print {
    display: none !important;
  }
}
.mu-app-root .mu-link,
.mu-app-root .mu-link-icon {
  transition: color 0.3s ease;
  position: relative;
  font-weight: 500;
  color: var(--m-color-interactive);
  outline-color: var(--m-color-interactive-darker);
  text-decoration: none;
}
.mu-app-root .mu-link:active, .mu-app-root .mu-link:focus:not(:hover),
.mu-app-root .mu-link-icon:active,
.mu-app-root .mu-link-icon:focus:not(:hover) {
  color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-link:not(:hover, :focus):visited,
.mu-app-root .mu-link-icon:not(:hover, :focus):visited {
  color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-link:hover, .mu-app-root .mu-link:focus {
  color: var(--m-color-interactive-dark);
  text-decoration: underline;
}
.mu-app-root .mu-link__text {
  position: relative;
  vertical-align: baseline;
}
.mu-app-root .mu-link-icon {
  display: inline-flex;
  align-items: baseline;
}
.mu-app-root .mu-link-icon:hover .mu-link__text, .mu-app-root .mu-link-icon:focus .mu-link__text {
  text-decoration: underline;
}
.mu-app-root .mu-link-icon > :not(:first-child) {
  margin-left: 8px;
}
.mu-app-root .mu-button-primary {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-primary {
  color: var(--m-color-white);
  background: var(--m-color-interactive);
}
.mu-app-root .mu-button-primary {
  cursor: pointer;
}
.mu-app-root .mu-button-primary:hover {
  color: var(--m-color-white);
  background: var(--m-color-interactive-dark);
  border-color: var(--m-color-interactive-dark);
}
.mu-app-root .mu-button-primary:active, .mu-app-root .mu-button-primary:focus:not(:hover) {
  color: var(--m-color-white);
  background: var(--m-color-interactive-darker);
  border-color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-button-secondary {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-secondary {
  color: var(--m-color-interactive);
  background: var(--m-color-white);
}
.mu-app-root .mu-button-secondary {
  cursor: pointer;
}
.mu-app-root .mu-button-secondary:hover {
  color: var(--m-color-white);
  background: var(--m-color-interactive-dark);
  border-color: var(--m-color-interactive-dark);
}
.mu-app-root .mu-button-secondary:active, .mu-app-root .mu-button-secondary:focus:not(:hover) {
  color: var(--m-color-white);
  background: var(--m-color-interactive-darker);
  border-color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-button-disabled {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-disabled {
  cursor: default;
  color: var(--m-color-disabled);
  background: var(--m-color-white);
  border-color: var(--m-color-disabled);
}
.mu-app-root .mu-button-disabled {
  cursor: pointer;
}
.mu-app-root .mu-button-disabled:hover {
  color: var(--m-color-white);
  background: var(--m-color-interactive-dark);
  border-color: var(--m-color-interactive-dark);
}
.mu-app-root .mu-button-disabled:active, .mu-app-root .mu-button-disabled:focus:not(:hover) {
  color: var(--m-color-white);
  background: var(--m-color-interactive-darker);
  border-color: var(--m-color-interactive-darker);
}
.mu-app-root .mu-button-waiting {
  transition-property: background-color, border-color, color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border: 1px solid var(--m-color-interactive);
  font-family: inherit;
  text-decoration: none;
  appearance: none;
  outline-color: var(--m-color-interactive-darker);
  outline-offset: 2px;
  outline-width: 1px;
  user-select: none;
}
.mu-app-root .mu-button-waiting {
  cursor: progress;
  color: var(--m-color-white);
  background: var(--m-color-interactive-light);
  border-color: var(--m-color-interactive-light);
}

.mu-button-primary {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: inherit;
  border-radius: 4px;
  padding: 10px 16px;
  font-weight: 500;
  min-height: 44px;
}

.mpo-lien-chevron {
  transition: color 0.3s ease;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: var(--m-color-interactive);
  text-decoration: none;
  outline-color: var(--m-color-interactive);
}
.mpo-lien-chevron svg {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.mpo-lien-chevron:hover, .mpo-lien-chevron:focus {
  text-decoration: underline;
}
.mpo-lien-chevron:hover {
  color: var(--m-color-interactive-light);
}
.mpo-lien-chevron:active, .mpo-lien-chevron:focus:not(:hover) {
  color: var(--m-color-interactive-dark);
}

.mpo-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mpo-message-erreur {
  display: none;
  color: var(--m-color-error);
  border-left: 4px solid currentColor;
  padding: 16px 16px 16px 12px;
  background-color: var(--m-color-grey-lightest);
  border-radius: 0 6px 6px 0;
}
@media (min-width: 768px) {
  .mpo-message-erreur {
    padding-left: 16px;
  }
}
.mpo-message-erreur svg {
  flex-shrink: 0;
  font-size: 24px;
  margin-right: 12px;
}
@media (min-width: 768px) {
  .mpo-message-erreur svg {
    margin-right: 16px;
    font-size: 32px;
  }
}
.mpo-message-erreur p {
  margin: 0;
  color: var(--m-color-text);
}
.mpo-message-erreur.mpo--afficher {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.mpo-textfield {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 288px;
}
.mpo-textfield.mpo--erreur .mpo-textfield__label {
  color: var(--m-color-error);
}
.mpo-textfield.mpo--erreur .mpo-textfield__input {
  border-color: var(--m-color-error);
}
.mpo-textfield.mpo--erreur:focus-within .mpo-textfield__input {
  outline: 1px solid var(--m-color-error);
}
.mpo-textfield:focus-within:not(.mpo--erreur) .mpo-textfield__label {
  color: var(--m-color-interactive);
}
.mpo-textfield:focus-within:not(.mpo--erreur) .mpo-textfield__input {
  border-color: var(--m-color-interactive);
  outline: 1px solid var(--m-color-interactive);
}
.mpo-textfield__label {
  transition: color 0.3s ease;
  position: absolute;
  background-color: var(--m-color-white);
  padding: 0 3px;
  margin-left: 9px;
  color: currentColor;
  transform: translateY(-50%);
  cursor: pointer;
}
.mpo-textfield__label {
  font-size: calc(14 / 16 * 1rem);
}
.mpo-textfield__input {
  transition: border-color 0.3s ease, outline 0.3s ease;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 12px;
  color: var(--m-color-text);
  border: 1px solid var(--m-color-grey);
  border-radius: 3px;
  outline: none;
}
.mpo-textfield__input:hover {
  border-color: var(--m-color-grey-black);
}
.mpo-textfield__messages {
  margin-top: 4px;
}
.mpo-textfield__messages {
  font-size: calc(14 / 16 * 1rem);
}
.mpo-textfield__messages > :not(:first-child) {
  margin-top: 4px;
}
.mpo-textfield__messages > :first-child {
  margin-top: 0;
}
.mpo-textfield__message-aide {
  color: var(--m-color-text-light);
}
.mpo-textfield__message-erreur {
  display: flex;
  align-items: center;
  color: var(--m-color-error);
}
.mpo-textfield__message-erreur svg {
  flex-shrink: 0;
  margin-right: 4px;
}
.mpo-textfield__message-erreur svg {
  font-size: calc(14 / 16 * 1rem);
}

.m-message {
  margin-bottom: 16px;
  text-align: left;
  background-color: var(--m-color-grey-lightest);
}
.m-message.m--is-state-information .m-message__wrap {
  border-left-color: var(--m-color-interactive);
}
.m-message.m--is-state-information .m-message__icon {
  color: var(--m-color-interactive);
}
.m-message.m--is-state-confirmation .m-message__wrap {
  border-left-color: var(--m-color-success);
}
.m-message.m--is-state-confirmation .m-message__icon {
  color: var(--m-color-success);
}
.m-message.m--is-state-warning .m-message__wrap {
  border-left-color: var(--m-color-warning);
}
.m-message.m--is-state-warning .m-message__icon {
  color: var(--m-color-warning);
}
.m-message.m--is-state-error .m-message__wrap {
  border-left-color: var(--m-color-error);
}
.m-message.m--is-state-error .m-message__icon {
  color: var(--m-color-error);
}
.m-message__wrap {
  position: relative;
  display: flex;
  padding: 16px;
  background: var(--m-color-grey-lightest);
  border-left-width: 4px;
  border-left-style: solid;
  border-radius: 0 6px 6px 0;
}
.m-message__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.m-message__icon svg {
  font-size: calc(var(--m-font-size-h2) / 16 * 1rem);
}
.m-message__body {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - var(--m-font-size-h2) * 1px);
  margin-left: 16px;
}
.m-message__title {
  margin-top: 0 !important;
  font-size: 1.125rem !important;
  text-transform: capitalize;
}
.m-message__title + .m-message__text {
  margin-top: 4px;
}
.m-message__text {
  position: relative;
}

.mpo-entete {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  height: 78px;
  background: var(--m-color-white);
  padding: 8px 16px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .mpo-entete {
    position: sticky;
    padding-left: 32px;
    padding-right: 0;
  }
}
.mpo-entete::before, .mpo-entete::after {
  position: absolute;
  top: 0;
  z-index: 0;
  content: "";
  height: 8px;
}
.mpo-entete::before {
  left: 0;
  right: 20%;
  background: var(--m-color-ul-red);
}
.mpo-entete::after {
  left: 80%;
  right: 0;
  background: var(--m-color-ul-yellow);
}
.mpo-entete__zone-logos.mpo-entete__zone-logos {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--m-color-black);
  cursor: pointer;
  text-decoration: none;
  outline-color: var(--m-color-interactive);
  padding: 8px;
  margin: 0 0 0 -8px;
  border-radius: 4px;
}
.mpo-entete__logo-ul {
  width: 100px;
}
.mpo-entete__logo-mpo {
  display: flex;
  align-items: center;
}
.mpo-entete__logo-mpo {
  font-size: calc(20 / 16 * 1rem);
}
.mpo-entete__logo-mpo::before {
  content: "";
  width: 1px;
  height: 20px;
  background: var(--m-color-border);
  margin: 0 16px;
}
.mpo-entete__logo-mpo b {
  font-weight: 575;
}

.mpo-entete-ulaval {
  position: relative;
  display: flex;
  align-items: center;
}
.mpo-entete-ulaval::before {
  right: 20%;
}
@media (min-width: 768px) {
  .mpo-entete-ulaval::before {
    right: 50%;
  }
}
.mpo-entete-ulaval::after {
  left: 80%;
}
@media (min-width: 768px) {
  .mpo-entete-ulaval::after {
    left: 50%;
  }
}
.mpo-entete-ulaval::before, .mpo-entete-ulaval::after, .mpo-entete-ulaval__contenu::before, .mpo-entete-ulaval__contenu::after {
  position: absolute;
  top: 0;
  z-index: 0;
  content: "";
  height: 30px;
}
.mpo-entete-ulaval::before, .mpo-entete-ulaval__contenu::before {
  left: 0;
  background: var(--m-color-ul-yellow);
}
.mpo-entete-ulaval::after, .mpo-entete-ulaval__contenu::after {
  right: 0;
  background: var(--m-color-ul-red);
}
.mpo-entete-ulaval__contenu {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 16px 20px 16px;
}
@media (min-width: 768px) {
  .mpo-entete-ulaval__contenu {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.mpo-entete-ulaval__contenu::before, .mpo-entete-ulaval__contenu::after {
  z-index: 1;
}
.mpo-entete-ulaval__contenu::after {
  left: calc(0% + 160px);
  border-left: 2px solid var(--m-color-white);
}
@media (min-width: 768px) {
  .mpo-entete-ulaval__contenu::after {
    left: calc(0% + 200px);
  }
}
.mpo-entete-ulaval__logo-ul {
  width: 125px;
}
@media (min-width: 768px) {
  .mpo-entete-ulaval__logo-ul {
    width: 144px;
  }
}

.mpo-corps-page-erreur {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 40px 16px;
}
@media (min-width: 768px) {
  .mpo-corps-page-erreur {
    background-color: var(--m-color-grey-lighter);
    padding: 40px 32px;
  }
}
.mpo-corps-page-erreur__boite {
  width: 100%;
  max-width: 540px;
  background-color: var(--m-color-white);
}
@media (min-width: 768px) {
  .mpo-corps-page-erreur__boite {
    padding: 40px 32px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
.mpo-corps-page-erreur__entete .mpo-corps-page-erreur__titre {
  color: var(--m-color-text);
  text-align: center;
  margin: 0;
}
.mpo-corps-page-erreur__contenu-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-avertissement {
  color: var(--m-color-warning);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-avertissement::before {
  background-color: var(--m-color-warning-lightest);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-confirmation {
  color: var(--m-color-success);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-confirmation::before {
  background-color: var(--m-color-success-lightest);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-information {
  color: var(--m-color-interactive);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-information::before {
  background-color: var(--m-color-information);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-erreur {
  color: var(--m-color-error);
}
.mpo-corps-page-erreur__contenu-image.mpo--is-etat-erreur::before {
  background-color: var(--m-color-error-lightest);
}
.mpo-corps-page-erreur__contenu-image img {
  z-index: 1;
  width: 130px;
}
.mpo-corps-page-erreur__contenu-image::before {
  position: absolute;
  top: calc(50% - 11px);
  right: 0;
  left: 0;
  transform: translate3d(0, -50%, 0);
  height: 80px;
  z-index: 0;
  content: "";
}
.mpo-corps-page-erreur__contenu-image::after {
  width: 150px;
  height: 5px;
  border-radius: 50%;
  content: "";
  background-color: var(--m-color-grey-lighter);
  margin-top: 8px;
}
.mpo-corps-page-erreur__svg {
  z-index: 1;
}
.mpo-corps-page-erreur__svg svg {
  width: 130px;
}
.mpo-corps-page-erreur__corps {
  margin-top: 32px;
}
.mpo-corps-page-erreur__corps p {
  margin: 16px 0 0;
}
.mpo-corps-page-erreur__corps > :first-child {
  margin-top: 0;
}
.mpo-corps-page-erreur__liste {
  margin-bottom: 0;
}
.mpo-corps-page-erreur__liste li {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .mpo-version-mobile {
    display: none;
  }
}

.mpo-version-desktop {
  display: none;
}
@media (min-width: 768px) {
  .mpo-version-desktop {
    display: block;
  }
}

.mpo-pied-page {
  flex-shrink: 0;
  text-align: center;
  background-color: var(--m-color-white);
  color: var(--m-color-grey-darker);
}
.mpo-pied-page {
  font-size: calc(12 / 16 * 1rem);
}
.mpo-pied-page__navigation {
  border-top: 1px solid var(--m-color-grey-light);
  padding: 16px;
}
@media (min-width: 768px) {
  .mpo-pied-page__navigation {
    padding: 8px 32px;
  }
}
.mpo-pied-page__mention-legale {
  border-top: 1px solid var(--m-color-grey-light);
  padding: 16px;
  background-color: var(--m-color-grey-lighter);
}
.mpo-pied-page__liste-liens {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.mpo-pied-page__liste-liens li {
  display: flex;
  text-align: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .mpo-pied-page__liste-liens {
    flex-direction: column;
    align-items: flex-start;
  }
  .mpo-pied-page__liste-liens li:not(:first-child) {
    margin-top: 8px;
  }
}
@media (min-width: 768px) {
  .mpo-pied-page__liste-liens {
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
  .mpo-pied-page__liste-liens li:not(:last-child) {
    margin-right: 16px;
  }
}
.mpo-pied-page__lien.mpo-pied-page__lien {
  transition: color 0.3s ease;
  padding: 4px 0;
  outline-color: var(--m-color-interactive);
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}
.mpo-pied-page__lien.mpo-pied-page__lien, .mpo-pied-page__lien.mpo-pied-page__lien:visited {
  color: var(--m-color-grey-darker);
}
.mpo-pied-page__lien.mpo-pied-page__lien:hover, .mpo-pied-page__lien.mpo-pied-page__lien:focus, .mpo-pied-page__lien.mpo-pied-page__lien:active {
  color: var(--m-color-black);
  text-decoration: underline;
}

.mpo-gabarit-page {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.mpo-gabarit-page__entete, .mpo-gabarit-page__corps {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .mpo-gabarit-page__entete, .mpo-gabarit-page__corps {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.mpo-gabarit-page__conteneur-entete {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  background: var(--m-color-grey-lighter);
}
@media (min-width: 768px) {
  .mpo-gabarit-page__conteneur-entete {
    min-height: 120px;
  }
}
.mpo-gabarit-page__conteneur-entete h1 {
  font-weight: 350;
}
.mpo-gabarit-page__conteneur-entete h1 {
  font-size: calc(var(--m-font-size-h2) / 16 * 1rem);
}
.mpo-gabarit-page__corps {
  flex: 1 1 auto;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 48px;
}
.mpo-gabarit-page__corps > :first-child {
  margin-top: 0;
}
